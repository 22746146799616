/* eslint-disable react-hooks/exhaustive-deps */
import { faChevronRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jwtDecode from 'jwt-decode';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  FormControl,
  InputGroup,
  Row,
  Spinner,
  Toast,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import shortid from 'shortid';
import { getOrgList, getOrgListByUser } from '../../../../actions/organization.actions';
import {
  getSurveysSummary,
  getSurveysSummaryTypes,
  hideErrorToast,
} from '../../../../actions/survey.summary.action';
import SurveyInfoModal from '../../../../Components/SurveyInfoModal';
import types from '../../../../types';

const ADMIN_ROLES = ['SUPER_ADMIN', 'ORGANIZATION_ADMIN'];

const SurveyTab = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, loadingTypes, surveys, surveyTypes, error } = useSelector(
    (state) => state.surveySummary
  );
  const storeJwt = useSelector((state) => state.session.jwt);
  const jwtObj = jwtDecode(storeJwt);
  const [queryParams, setQueryParams] = useState({ title: '' });

  const [typeButtonsStates, setTypeButtonsStates] = useState([]);
  const { organizations: orgList, loading: orgLoading } = useSelector(
    (state) => state.organizations
  );

  const handleViewInfo = (surveyId, surveyInfo, surveyTitle) => {
    dispatch({
      type: types.reducerTypes.SURVEY_SUMMARY_INFO_MODAL_SHOW,
      payload: {
        selectedId: surveyId,
        selectedInfo: surveyInfo,
        selectedTitle: surveyTitle,
      },
    });
  };

  const searchTitleDebounced = useCallback(
    debounce((titleToSearch) => {
      setQueryParams((prev) => ({ ...prev, title: titleToSearch }));
    }, 500),
    []
  );

  const handleSurveyTitleSearch = (e) => {
    const titleToSearch = e.target.value.trim();
    if (
      titleToSearch !== queryParams.title &&
      (titleToSearch.length === 0 || titleToSearch.length >= 3)
    ) {
      searchTitleDebounced(titleToSearch);
    }
  };

  const handleFilterByType = (e) => {
    const selectedIndex = e.target.value;
    const newTypeButtonsStates = typeButtonsStates.map((type, index) => ({
      ...type,
      selected: index.toString() === selectedIndex ? !type.selected : false,
    }));
    setTypeButtonsStates(newTypeButtonsStates);

    if (newTypeButtonsStates[selectedIndex].selected) {
      setQueryParams((prev) => ({ ...prev, type: typeButtonsStates[selectedIndex].value }));
    } else {
      setQueryParams((prev) => ({ ...prev, type: '' }));
    }
  };

  const handleOrderByYear = (asc) => {
    setQueryParams((prev) => ({ ...prev, orderBy: 'year', orderAsc: asc }));
  };

  const handleRedirectSurvey = (surveyId) => {
    dispatch({ type: types.reducerTypes.SURVEY_DETAILS_CLEAR });
    history.push(`/surveys/${surveyId}`);
  };

  useEffect(() => {
    localStorage.removeItem('sectionId');
    localStorage.removeItem('questionId');
    dispatch({ type: types.reducerTypes.SURVEY_DETAILS_CLEAR });
  }, []);

  useEffect(() => {
    if (storeJwt) {
      dispatch(getSurveysSummaryTypes(storeJwt));
      dispatch(getSurveysSummary(storeJwt));
      if (jwtObj.role === 'ORGANIZATION_ADMIN') {
        dispatch(getOrgListByUser(jwtObj.userId, storeJwt));
      } else {
        dispatch(getOrgList(null, storeJwt));
      }
    }
  }, [storeJwt]);

  useEffect(() => {
    dispatch(getSurveysSummary(storeJwt, queryParams));
  }, [queryParams]);

  useEffect(() => {
    if (typeButtonsStates.length === 0) {
      const buttonStates = surveyTypes.map((type, index) => ({
        value: type,
        key: index,
        selected: false,
        disabled: false,
      }));
      setTypeButtonsStates(buttonStates);
    }
  }, [surveyTypes]);

  const handleOrgChange = (org) => {
    setQueryParams((prev) => ({ ...prev, organization: org?.value }));
  };

  const onViewSurveysClick = () => {
    history.push('/survey-topics');
  };

  return (
    <>
      <Row className="mt-3 w-100">
        <Col sm="2" md="2" lg="2">
          <DropdownButton variant="secondary" id="order-by-dropdown" size="sm" title="Order By">
            <Dropdown.Item onClick={() => handleOrderByYear(false)}>Year Recent</Dropdown.Item>
            <Dropdown.Item onClick={() => handleOrderByYear(true)}>Year Oldest</Dropdown.Item>
          </DropdownButton>
        </Col>
        <Col sm="3" md="3" lg="3">
          <ButtonToolbar className="w-100">
            <div className="mr-2">Filters: </div>
            {loadingTypes && <div className="mr-2">loading filters ...</div>}
            {!loadingTypes && (
              <ButtonGroup>
                {typeButtonsStates?.map((type) => (
                  <Button
                    key={type.key}
                    size="sm"
                    variant="secondary"
                    className={
                      type.selected ? 'rounded-pill mx-2 btn-type-selected' : 'rounded-pill mx-2'
                    }
                    value={type.key}
                    onClick={handleFilterByType}
                  >
                    {type.value}
                  </Button>
                ))}
              </ButtonGroup>
            )}
          </ButtonToolbar>
        </Col>
        {!orgLoading && (
          <Col sm="2" md="2" lg="2">
            <Select
              placeholder="Select Organization"
              options={orgList.map((l) => ({
                label: l.name,
                value: l.id,
              }))}
              isClearable
              classNamePrefix="react-select-org"
              onChange={handleOrgChange}
            />
          </Col>
        )}
        <Col sm="3" md="3" lg="3">
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="text"
              placeholder="Search by title"
              aria-label="Search by title"
              aria-describedby="searchbytitle"
              onChange={handleSurveyTitleSearch}
              className="ml-1"
            />
          </InputGroup>
        </Col>
        <Col>
          <Button className="btn-view-survey w-100" onClick={onViewSurveysClick}>
            Search surveys by topic
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        {loading && (
          <Col key={shortid.generate()}>
            <Spinner animation="border" className="d-flex justify-center" />
          </Col>
        )}
        {!loading && surveys?.length === 0 && (
          <Col>
            <p>No results found</p>
          </Col>
        )}
        {!loading &&
          surveys?.map((survey) => (
            <Col key={shortid.generate()} sm="6" md="4" lg="3" className="my-2">
              <Card key={shortid.generate()} size="lg" className="h-100">
                {ADMIN_ROLES.indexOf(jwtObj.role) !== -1 && survey.published && (
                  <div className="survey-state-label published mt-3">
                    <span>PUBLISHED</span>
                  </div>
                )}
                {ADMIN_ROLES.indexOf(jwtObj.role) !== -1 && survey.draft && (
                  <div className="survey-state-label draft mt-3">
                    <span>DRAFT</span>
                  </div>
                )}
                {ADMIN_ROLES.indexOf(jwtObj.role) !== -1 && !survey.draft && !survey.published && (
                  <div className="survey-state-label not-published mt-3">
                    <span>NOT PUBLISHED</span>
                  </div>
                )}
                <Card.Body
                  className="card-body-size cursor-pointer"
                  onClick={() => handleRedirectSurvey(survey.id)}
                >
                  <Card.Title className="mb-5 card-title-size">{survey.title}</Card.Title>
                  <Card.Text className="card-text-size">
                    {survey.typeFullName} ({survey.type})
                  </Card.Text>
                  {survey.organization?.name && (
                    <Card.Text className="card-text-size">{survey.organization?.name}</Card.Text>
                  )}
                  <Card.Text className="card-text-size">
                    {survey.surveySummaryDetailsDto?.dateAdministrated}
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="card-footer-size px-1 py-1">
                  <Button
                    size="sm"
                    className="w-100 text-dark pt-2 bg-white border-0 view-info-btn-allign"
                    onClick={() =>
                      handleViewInfo(
                        survey.id,
                        {
                          ...(survey.surveySummaryDetailsDto || {}),
                          organization: survey.organization,
                        },
                        survey.title
                      )
                    }
                  >
                    View Info <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          ))}
      </Row>
      <SurveyInfoModal handleRedirectSurvey={handleRedirectSurvey} />
      <Toast
        className="survey-summary-error-toast-container"
        onClose={() => dispatch(hideErrorToast())}
        show={!!error}
        delay={3000}
        autohide
      >
        <Toast.Header>
          <h3 className="mr-auto">Error</h3>
        </Toast.Header>
        <Toast.Body>{error}</Toast.Body>
      </Toast>
    </>
  );
};

export default SurveyTab;
