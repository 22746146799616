/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import themes from '../../../../constants/themes';

import types from '../../../../types';
import './styles.css';

const SurveySTypeQuestionEditForm = ({ handleEditMode }) => {
  const dispatch = useDispatch();
  const [selectedTheme, setSelectedTheme] = useState(0);
  const [questionText, setQuestionText] = useState('');
  const [answers, setAnswers] = useState([]);
  const [filterType, setFilterType] = useState('');
  const { sample, dimensions } = useSelector((state) => state.surveyWizard);

  const onQuestionTextChange = (e) => {
    setQuestionText(e.target.value);
  };

  const onAnswerChange = (e) => {
    const indexAnswer = answers.findIndex((a) => a.id === e.target.id);
    if (indexAnswer !== -1) {
      const answersToUpdate = [...answers];
      answersToUpdate[indexAnswer].value = e.target.value;
      setAnswers([...answersToUpdate]);
    }
  };

  const onSave = () => {
    answers.forEach((an) => {
      dimensions[0].Data.allowedValues[an.id] = an.value;
    });
    sample.Data.label = questionText;
    sample.Data.filterType = filterType;
    dispatch({ type: types.reducerTypes.SURVEY_SAMPLE_GET_SUCCESS, payload: sample });
    dispatch({
      type: types.reducerTypes.SURVEY_SAMPLE_DIMENSIONS_GET_SUCCESS,
      payload: dimensions,
    });
  };

  const onFilterTypeChange = (e) => {
    setFilterType(e.target.value);
  };

  useEffect(() => {
    if (sample) {
      setQuestionText(sample.Data.label);
      setFilterType(sample.Data.filterType);
    }
    if (dimensions) {
      const { allowedValues } = dimensions[0].Data;
      setAnswers(
        Object.keys(allowedValues)
          .filter((key) => key >= 0)
          .map((key) => ({ id: key, value: allowedValues[key] }))
      );
    }
  }, [sample, dimensions]);

  return (
    <Col md="3" className="p-3 survey-sidebar edit-mode">
      <span
        className="d-flex"
        onClick={handleEditMode}
        onKeyDown={handleEditMode}
        role="button"
        tabIndex={0}
      >
        <FontAwesomeIcon size="sm" className="mr-3" icon={faChevronLeft} />
        Back to overview
      </span>
      <Col className="info-edit-form">
        <h4>Question Text</h4>
        <Form.Control
          as="textarea"
          value={questionText}
          onChange={onQuestionTextChange}
          style={{ height: '100px' }}
          maxLength={500}
          placeholder="Survey Description"
        />
        <h4>Answers</h4>
        {answers.map((answer) => {
          return (
            <Form.Control
              key={answer.id}
              id={answer.id}
              value={answer.value}
              type="text"
              onChange={onAnswerChange}
            />
          );
        })}
        <h4>Filter Category</h4>
        <ListGroup>
          <ListGroup.Item>
            <Form.Check
              type="radio"
              checked={filterType === 'NONE'}
              id="filter-none"
              label="None"
              value="NONE"
              onClick={onFilterTypeChange}
            />
          </ListGroup.Item>
          <ListGroup.Item>
            <Form.Check
              checked={filterType === 'LOCATION'}
              type="radio"
              id="filter-location"
              label="Location"
              value="LOCATION"
              onClick={onFilterTypeChange}
            />
          </ListGroup.Item>
          <ListGroup.Item>
            <Form.Check
              checked={filterType === 'ATTRIBUTE'}
              type="radio"
              id="filter-attribute"
              label="Attribute"
              value="ATTRIBUTE"
              onClick={onFilterTypeChange}
            />
          </ListGroup.Item>
          <ListGroup.Item>
            <Form.Check
              checked={filterType === 'QUESTION'}
              type="radio"
              id="filter-question"
              label="Question"
              value="QUESTION"
              onClick={onFilterTypeChange}
            />
          </ListGroup.Item>
        </ListGroup>
        <h4>Select Color Scheme</h4>
        {themes.map((theme) => (
          <div key={`theme-${theme.index}`}>
            <div
              className={`colors-rounded ${theme.index === selectedTheme?.index ? 'selected' : ''}`}
              onClick={() => setSelectedTheme(theme)}
              role="button"
              style={{ display: 'flex' }}
            >
              {theme.basic.map((color) => (
                <div
                  role="button"
                  key={`color-${color}`}
                  style={{ backgroundColor: color, flex: 1, height: 20 }}
                />
              ))}
            </div>
            <span>{theme.label}</span>
          </div>
        ))}
      </Col>
      <Button variant="primary" onClick={onSave} className="btn btn-custom dark mt-2">
        Save
      </Button>
    </Col>
  );
};

SurveySTypeQuestionEditForm.propTypes = {
  handleEditMode: PropTypes.func.isRequired,
};

export default SurveySTypeQuestionEditForm;
