import types from '../types';

const initialState = {
  loading: false,
  loadingTypes: false,
  surveys: [],
  surveyTypes: [],
  selectedSurveyInfo: {},
  selectedSurveyId: 0,
  selectedSurveyTitle: '',
  showInfoModal: false,
  error: '',
};

const surveySummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.SURVEY_SUMMARY_PENDING:
      return {
        ...initialState,
        loading: true,
      };
    case types.reducerTypes.SURVEY_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        surveys: action.payload,
      };
    case types.reducerTypes.SURVEY_SUMMARY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.reducerTypes.SURVEY_SUMMARY_INFO_MODAL_HIDE:
      return {
        ...state,
        selectedSurveyId: 0,
        selectedSurveyInfo: {},
        showInfoModal: false,
      };
    case types.reducerTypes.SURVEY_SUMMARY_INFO_MODAL_SHOW:
      return {
        ...state,
        selectedSurveyInfo: action.payload.selectedInfo,
        selectedSurveyId: action.payload.selectedId,
        selectedSurveyTitle: action.payload.selectedTitle,
        showInfoModal: true,
      };
    case types.reducerTypes.SURVEY_SUMMARY_TYPES_PENDING:
      return {
        ...state,
        loadingTypes: true,
      };
    case types.reducerTypes.SURVEY_SUMMARY_TYPES_SUCCESS:
      return {
        ...state,
        surveyTypes: action.payload.filter((st) => st !== 'MLI'),
        loadingTypes: false,
      };
    case types.reducerTypes.SURVEY_SUMMARY_HIDE_ERROR_TOAST:
      return {
        ...state,
        error: '',
      };
    default:
  }
  return state;
};

export default surveySummaryReducer;
